import {
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  AdminPage,
  AdminCard,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { useState } from 'react';

const CategoryNew = () => {
  const { config } = useBlueprintAdmin();
  const [tag, setTag] = useState(undefined);
  const navigate = useNavigate();

  const deckAPI = new AdminScopedAPI(config, 'DeckAPI');

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Please enter a name';
    }

    if (!values.tagType) {
      errors.tagType = 'Please enter a tag type';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    const response = await deckAPI.post({
      path: `/admin/decks/meta/categories`,
      body: values,
    });

    if (response.success) {
      navigate(`/categories/${response.data.slug}`);
      return true;
    } else {
      console.error(response.message ?? 'error creating tag category');
      return false;
    }
  };

  const TagForm = ({ tag }: any) => {
    const formik = useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      validate: validate,
      initialValues: {
        name: '',
        tagType: '',
      },
      onSubmit: async (values) => {
        await onSubmit(values);
      },
    });

    return (
      <Grid xs={12} container>
        <AdminCard title='Details'>
          <form onSubmit={formik.handleSubmit}>
            <FormControl margin='dense' fullWidth style={{ paddingBottom: 20 }}>
              <Typography variant='subtitle1'>Name</Typography>
              <TextField
                error={formik.errors.name !== undefined}
                helperText={formik.errors.name}
                defaultValue={formik.values.name}
                value={formik.values.name}
                id='name'
                name='name'
                type='text'
                variant='outlined'
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl margin='dense' fullWidth style={{ paddingBottom: 20 }}>
              <Typography variant='subtitle1'>Tag Type</Typography>
              <Select
                error={formik.errors.tagType !== undefined}
                defaultValue={formik.values.tagType}
                value={formik.values.tagType}
                id='tagType'
                name='tagType'
                variant='outlined'
                onChange={formik.handleChange}
              >
                <MenuItem value='deck'>Deck</MenuItem>
                <MenuItem value='slide'>Slide</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <Button
                variant='contained'
                size='large'
                id='submit'
                type='submit'
              >
                Save Changes
              </Button>
            </FormControl>
          </form>
        </AdminCard>
      </Grid>
    );
  };

  return (
    <>
      <AdminPage title='New Tag Category'>
        <TagForm tag={tag} />
      </AdminPage>
    </>
  );
};

export default CategoryNew;
