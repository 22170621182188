import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  AdminPage,
  AdminRecord,
  AdminTableRow,
  AdminCard,
  AdminAssetViewer,
  useBlueprintAdmin,
  AdminModal,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { useState } from 'react';
import { Link as UiLink } from '@mui/material';
import { Link } from 'react-router-dom';

const DeckDetail = () => {
  const { id } = useParams<{ id: string }>();

  const [isModalOpen, setIsModalOpen] = useState<any>();
  const [selectedSlide, setSelectedSlide] = useState<any>();

  const openModal = (data) => {
    setSelectedSlide(data);
    setIsModalOpen(true);
  };

  const closeModal = (data) => {
    setIsModalOpen(false);
  };

  const modalTemplate = () => (
    <>
      {selectedSlide && (
        <>
          <AdminAssetViewer id={selectedSlide.assetId} />
          <p></p>
        </>
      )}
    </>
  );

  const deckTemplate = (data) => (
    <Grid xs={12} container>
      <Grid xs={8} item paddingRight={10}>
        <AdminCard title='Details'>
          <Table>
            <TableBody>
              <AdminTableRow key={1}>
                <TableCell>ID</TableCell>
                <TableCell>{data.id}</TableCell>
              </AdminTableRow>
              <AdminTableRow key={2}>
                <TableCell>Title</TableCell>
                <TableCell>{data.title}</TableCell>
              </AdminTableRow>
              <AdminTableRow key={3}>
                <TableCell>Type</TableCell>
                <TableCell>{data.deckType}</TableCell>
              </AdminTableRow>
              <AdminTableRow key={4}>
                <TableCell>Contact</TableCell>
                <TableCell>{data.contact}</TableCell>
              </AdminTableRow>
              <AdminTableRow key={5}>
                <TableCell>Email</TableCell>
                <TableCell>{data.email}</TableCell>
              </AdminTableRow>
              <AdminTableRow key={6}>
                <TableCell>Created By</TableCell>
                <TableCell>{data.createdBy}</TableCell>
              </AdminTableRow>
            </TableBody>
          </Table>
        </AdminCard>
      </Grid>
      <Grid xs={4} item>
        <AdminCard title='Tags'>
          <Table>
            <TableBody>
              {data.tags.map((tagData: any) => (
                <TableRow key={tagData.id}>
                  <TableCell>{tagData.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AdminCard>
      </Grid>
      <Grid xs={12} item>
        <AdminCard title='Slides'>
          <Grid xs={12} container>
            {data.slides.map((slideData: any) => (
              <Grid xs={2} item key={slideData.id}>
                <UiLink
                  component={Link}
                  to='#'
                  color='primary'
                  onClick={() => openModal(slideData)}
                >
                  <div
                    key={slideData.id}
                    style={{
                      padding: 10,
                      borderColor: '#ccc',
                      borderWidth: 0.5,
                      borderStyle: 'solid',
                    }}
                  >
                    <AdminAssetViewer id={slideData.assetId} />
                    <div style={{ minHeight: '32px' }}>
                      {slideData.qual && (
                        <Chip
                          size='small'
                          label='Qual'
                          color='primary'
                          variant='outlined'
                        />
                      )}
                      {slideData.qual && (
                        <Chip
                          size='small'
                          label='Tag'
                          color='primary'
                          variant='outlined'
                        />
                      )}
                    </div>
                  </div>
                </UiLink>
              </Grid>
            ))}
          </Grid>
        </AdminCard>
      </Grid>
      <Grid xs={6} item></Grid>
    </Grid>
  );

  return (
    <>
      <AdminPage title='Deck Detail'>
        <AdminRecord
          form={false}
          url={`/admin/decks/${id}`}
          itemTemplate={deckTemplate}
        />
        <AdminModal
          title='Thing'
          body={modalTemplate()}
          isOpen={isModalOpen}
          closeModal={closeModal}
          size='xl'
        />
      </AdminPage>
    </>
  );
};

export default DeckDetail;
