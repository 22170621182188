import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TableCell,
  TableCellProps,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Link as UiLink } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  AdminCard,
  AdminModal,
  AdminPage,
  AdminPaginatedList,
  AdminPaginatedListV2,
  AdminTableRow,
  toastSuccess,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import searchClient, { fetchTagOptions } from '../../utils/search';
import TagBar from '../../components/TagBar';
import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';

interface Tag {
  label: string;
  slug: string;
}

interface IBulkPayload {
  decks: Array<string>;
  tagsToAdd: Array<Tag>;
  tagsToRemove: Array<Tag>;
}

const fetchData =
  (searchTerm: string) =>
  ({ pageParam = 1 }) => {
    return searchClient
      .index('decks')
      .search(searchTerm, { hitsPerPage: 20, page: pageParam })
      .then((res) => {
        if (res.hits) {
          return res.hits;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

const DeckList = () => {
  const [content, setContent] = useState<Array<any>>([]);
  const [search, setSearch] = useState<string>('');
  const [tentativeTagAdditions, setTentativeTagAdditions] = useState<
    Array<Tag>
  >([]);
  const [tentativeTagRemovals, setTentativeTagRemovals] = useState<Array<Tag>>(
    []
  );

  const [tagOptions, setTagOptions] = useState<Array<any>>([]);
  const [tagSearchText, setTagSearchText] = useState<string>('');
  const [tagInputValueText, setTagInputValueText] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState<any>();
  const [selectedDecks, setSelectedDecks] = useState<Array<any>>([]);
  const { config } = useBlueprintAdmin();

  const api = new AdminScopedAPI(config, 'AdminRecord');

  const { data, isLoading, fetchNextPage, refetch } = useInfiniteQuery(
    'decks',
    fetchData(search),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    fetchTagOptions()
      .then((data) => {
        console.log(data);
        setTagOptions(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const closeModal = (data) => {
    setIsModalOpen(false);
  };

  const addTags = (_event: any, values: any) => {
    setTentativeTagAdditions([
      ...tentativeTagAdditions,
      { label: values.label, slug: values.value },
    ]);
  };

  const removeTags = (_event: any, values: any) => {
    setTentativeTagRemovals([
      ...tentativeTagRemovals,
      { label: values.label, slug: values.value },
    ]);
  };

  const removeTagFromList = (list: 'additions' | 'removals', value: any) => {
    if (list === 'additions') {
      setTentativeTagAdditions(
        tentativeTagAdditions.filter((item) => item.slug != value)
      );
    }

    if (list === 'removals') {
      setTentativeTagRemovals(
        tentativeTagRemovals.filter((item) => item.slug != value)
      );
    }
  };

  const modalTemplate = () => (
    <>
      {selectedDecks && (
        <Grid container spacing='12'>
          <Grid xs={12} item>
            <p>{selectedDecks.length} Decks are selected</p>
          </Grid>
          <Grid xs={12} container item spacing='12'>
            <Grid xs={6} item>
              <AdminCard title='Add Tags'>
                <Autocomplete
                  id='tagToAdd'
                  value={null}
                  autoComplete={false}
                  clearOnBlur={true}
                  blurOnSelect={true}
                  options={tagOptions}
                  onChange={addTags}
                  placeholder='Select a tag'
                  renderInput={(params) => (
                    <TextField {...params} label='Tag' />
                  )}
                />
                <TagBar
                  color='lightGreen'
                  tags={tentativeTagAdditions}
                  onDelete={(slug: string) => {
                    removeTagFromList('additions', slug);
                  }}
                  inline
                />
              </AdminCard>
            </Grid>
            <Grid xs={6} item>
              <AdminCard title='Remove Tags'>
                <Autocomplete
                  id='tagToRemove'
                  value={null}
                  autoComplete={false}
                  clearOnBlur={true}
                  blurOnSelect={true}
                  options={tagOptions}
                  onChange={removeTags}
                  placeholder='Select a tag'
                  renderInput={(params) => (
                    <TextField {...params} label='Tag' />
                  )}
                />
                <TagBar
                  color='#CD9A99'
                  tags={tentativeTagRemovals}
                  onDelete={(slug: string) => {
                    removeTagFromList('removals', slug);
                  }}
                  inline
                />
              </AdminCard>
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <AdminCard title='Save Changes'>
              <Typography style={{ paddingBottom: 10 }}>
                By saving these changes, all tags selected to be added or
                removed will be applied to the selected decks. Please note that
                it may take a few seconds for saving to be confirmed due to the
                async nature of this operation.
              </Typography>
              <Button
                variant='contained'
                onClick={async () => {
                  const payload: IBulkPayload = {
                    decks: selectedDecks,
                    tagsToAdd: tentativeTagAdditions,
                    tagsToRemove: tentativeTagRemovals,
                  };

                  // Save to the API
                  await api.post({
                    path: '/admin/decks/bulk/update',
                    body: payload,
                  });

                  // Close modal
                  setIsModalOpen(false);

                  // Pop up modal
                  toastSuccess('Deck tag changes were applied');
                }}
              >
                Save
              </Button>
            </AdminCard>
          </Grid>
        </Grid>
      )}
    </>
  );

  useEffect(() => {
    /**
     * when user submits search query refetch pagination with the latest filter
     */
    refetch()
      .then((response) => {
        if (response && response.data) {
          setContent(response.data.pages.flat());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [search]);

  useEffect(() => {
    if (data && data.pages.length > 0) {
      setContent(data.pages.flat());
    }
  }, [data?.pages.length]);

  const tagTemplate = (tags) => {
    const tagList = tags.map(({ name }) => name);

    return <>{tagList.join(', ')}</>;
  };

  const DeckTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
    fontSize: 12,
    padding: 0,
    paddingRight: 5,
  }));

  const isChecked = (id) => {
    if (selectedDecks) {
      if (selectedDecks.indexOf(id) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleCheckboxChange = (id, checked) => {
    setSelectedDecks((selectedDecks) =>
      selectedDecks.includes(id)
        ? selectedDecks.filter((f) => f !== id)
        : [...selectedDecks, id]
    );
  };

  const deckTemplate = ({ title, id, contact, tags }, index) => (
    <TableRow key={index}>
      <DeckTableCell>
        <Checkbox
          id={id}
          value={id}
          checked={isChecked(id)}
          onChange={(e) => handleCheckboxChange(id, e.target.checked)}
          style={{ padding: 15, margin: 0 }}
        ></Checkbox>
      </DeckTableCell>
      <DeckTableCell>{title}</DeckTableCell>
      <DeckTableCell>{contact}</DeckTableCell>
      <DeckTableCell>{tagTemplate(tags)}</DeckTableCell>
      <DeckTableCell>
        <UiLink component={Link} key={id} to={`/decks/${id}`}>
          View
        </UiLink>
      </DeckTableCell>
    </TableRow>
  );

  return (
    <AdminPage title='Decks'>
      <Grid container justifyContent='flex-end'>
        <Button
          variant='contained'
          onClick={() => {
            if (selectedDecks.length > 0) {
              setIsModalOpen(true);
            }
          }}
          disabled={selectedDecks.length === 0}
        >
          Manage ({selectedDecks.length} Decks)
        </Button>
      </Grid>

      <AdminPaginatedListV2
        headings={['', 'title', 'contact', 'tags', 'actions']}
        data={content}
        onInfiniteLoad={() => fetchNextPage()}
        isLoading={isLoading}
        itemTemplate={deckTemplate}
        loader={
          <tr className='loader' key={0}>
            <td>Loading ...</td>
          </tr>
        }
        search
        setSearchTerm={setSearch}
      />
      <AdminModal
        title='Bulk Actions'
        body={modalTemplate()}
        isOpen={isModalOpen}
        closeModal={closeModal}
        size='xl'
      />
    </AdminPage>
  );
};

const itemTemplate = ({ id, title }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/decks/${id}`}>
        {title}
      </UiLink>
    </TableCell>
  </AdminTableRow>
);

export default DeckList;
