import MeiliSearch from 'meilisearch';
import { getEnv } from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const searchClient = new MeiliSearch({
  host:
    getEnv('REACT_APP_SEARCH_API_DOMAIN') ??
    'https://search.davidsa5.sandbox.ddstudios.us',
  apiKey: getEnv('REACT_APP_SEARCH_API_KEY') ?? '', // if meilisearch was not configured correctly - force a 401 error
});

export const fetchTagOptions = async () => {
  const tagResponse = await searchClient.index('tags').search('');
  const tags: Array<any> = [];
  if (tagResponse.hits.length > 0) {
    for (const category of tagResponse.hits) {
      for (const tag of category.tags) {
        tags.push({
          value: tag.slug,
          label: tag.name,
        });
      }
    }
  }

  return tags;
};

export default searchClient;
