import {
  AdminCard,
  AdminPage,
  AdminToastTimed,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';

import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TagDelete = () => {
  const { config } = useBlueprintAdmin();
  const navigate = useNavigate();
  const { id, slug } = useParams<{ id: string; slug: string }>();
  const [error, setError] = useState(false);

  const deckAPI = new AdminScopedAPI(config, 'DeckAPI');

  const handleDelete = async (e) => {
    // SEND TO DELETE
    e.stopPropagation();
    e.preventDefault();

    await deckAPI
      .delete({ path: `/admin/decks/meta/tags/${slug}` })
      .then((res) => {
        if (res.success) {
          navigate(`/categories/${id}`);
        } else {
          setError(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/categories/${id}`);
  };

  return (
    <>
      {error && (
        <AdminToastTimed
          message='Error deleting tag, please make sure there are no decks with this tag'
          trigger={error}
          severity='error'
          time={100000}
        />
      )}
      {slug && (
        <AdminPage title='Delete Tag'>
          <AdminCard title=''>
            <h4>Are you sure you want to delete {slug} </h4>

            <Button onClick={(e) => handleDelete(e)} color='error'>
              Confirm Deletion
            </Button>
            <Button onClick={(e) => handleCancel(e)}>Cancel</Button>
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};
export default TagDelete;
