import {
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  AdminPage,
  AdminRecord,
  AdminTableRow,
  AdminCard,
  AdminTableHead,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { Link } from 'react-router-dom';
import { Link as UiLink } from '@mui/material';

const CategoryDetail = () => {
  const { id } = useParams<{ id: string }>();

  const categoryTemplate = (data) => (
    <Grid xs={12} container>
      <AdminCard title='Details' deleteAction={`/categories/${id}/delete`}>
        <Table>
          <TableBody>
            <AdminTableRow key={1}>
              <TableCell>ID</TableCell>
              <TableCell>{data.id}</TableCell>
            </AdminTableRow>
            <AdminTableRow key={2}>
              <TableCell>Type</TableCell>
              <TableCell>{data.tagType}</TableCell>
            </AdminTableRow>
            <AdminTableRow key={3}>
              <TableCell>Slug</TableCell>
              <TableCell>{data.slug}</TableCell>
            </AdminTableRow>
          </TableBody>
        </Table>
      </AdminCard>

      <AdminCard title='Tags' newAction={`/categories/${id}/new`}>
        <Table style={{ marginTop: 10 }}>
          <AdminTableHead>
            <TableRow></TableRow>
          </AdminTableHead>
          <TableBody>
            {data.tags.map((tag: any) => (
              <AdminTableRow key={data.id}>
                <TableCell key={tag.id}>
                  <UiLink component={Link} to={`/categories/${id}/${tag.slug}`}>
                    {tag.name}
                  </UiLink>
                </TableCell>
                <TableCell className='d-flex justify-content-end'>
                  <UiLink
                    component={Link}
                    to={`/categories/${id}/${tag.slug}/delete`}
                  >
                    <Button variant='contained' color='error'>
                      Delete
                    </Button>
                  </UiLink>
                </TableCell>
              </AdminTableRow>
            ))}
          </TableBody>
        </Table>
      </AdminCard>
    </Grid>
  );

  return (
    <>
      <AdminPage title='Category Detail'>
        <AdminRecord
          form={false}
          url={`/admin/decks/meta/categories/${id}`}
          itemTemplate={categoryTemplate}
        />
      </AdminPage>
    </>
  );
};

export default CategoryDetail;
