import React, { useState } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'material-react-toastify';
import { Route } from 'react-router-dom';

// This import is required for the react toast messages to popup
import 'material-react-toastify/dist/ReactToastify.css';

// This import is required for the markdown editor widget in the content new/edit page
import '@toast-ui/editor/dist/toastui-editor.css';

// This import is required for the collection editor page
import 'bootstrap/dist/css/bootstrap.min.css';

import { QueryClient, QueryClientProvider } from 'react-query';

// Include the base components from the core npm package
import {
  AdminProvider,
  BaseAdmin,
  getEnv,
  useMountedEffect,
  SessionTimeoutModal,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

// This is an override to allow you to define your own dashboard landing page
import Dashboard from './pages/dashboard';

// These are example pages for a `deck` object type
import DeckList from './pages/decks/list';
import DeckDetail from './pages/decks/detail';
import CategoryList from './pages/categories/list';
import CategoryDetail from './pages/categories/detail';
import TagDetail from './pages/categories/tags/detail';
import CategoryNewTag from './pages/categories/tags/new';
import CategoryNew from './pages/categories/new';
import TagDelete from './pages/categories/tags/delete';
import CategoryTagDelete from './pages/categories/delete';

// This is the base loaded App.
// If you want to include either AzureLogin or OktaLogin, please refer to the blueprint-ux-admin
// repo for examples of including either of those login patterns
const App = () => {
  const [sidebarRolebaseOptions, setSidebarRolebaseOptions] = useState<any>();
  const [sidebarReady, setSidebarReady] = useState<any>(false);
  const [sidebarFixedOptions, setSidebarFixedOptions] = useState<any>();
  const queryClient = new QueryClient();

  // This loads the sidebar for the editor, update the ./config/sidebar for any fixed
  // login items or roleBased items you want to customize for your admin
  useMountedEffect((mounted: boolean) => {
    // eslint-disable-next-line
    (async () => {
      const { roleBased, defaultFixed } = await import('./config/sidebar');
      setSidebarRolebaseOptions(roleBased);
      setSidebarFixedOptions(defaultFixed);
      setSidebarReady(true);
    })();

    // eslint-disable-next-line no-param-reassign
    return () => (mounted = false);
  }, []);

  // This is the base theme used for the Blueprint Admin, feel free to adjust or
  // extend however you might want to.
  const theme = createTheme({
    typography: {
      fontFamily: [
        '"Bw Modelica"',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h4: {
        fontWeight: 900,
      },
    },
    palette: {
      background: {
        default: '#fff',
      },
      primary: {
        main: '#0070eb',
        light: '#e0efff',
      },
      secondary: {
        main: '#bbdafd',
      },
    },
  });

  theme.components = {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          textAlign: 'left',
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        selected: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[800],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        style: {
          textDecoration: 'none',
        },
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // square corners
          textTransform: 'none', // removes uppercase transformation
          textDecoration: 'none',
        },
        containedPrimary: {
          '&:hover': {
            // changes colors for hover state
            backgroundColor: '#bbdafd', // from mui4: theme.palette.secondary.main,
            color: '#0070eb', // from mui4: theme.palette.primary.dark
          },
        },
        containedSecondary: {
          fontWeight: 700, // makes text bold
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary',
        underline: 'none',
      },
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SessionTimeoutModal />
        <CssBaseline />
        {sidebarReady && (
          // This AdminProvider houses all of the core logic for authentication/identity and
          // bootLoading the Blueprint Provider used in all downstream pages and components
          <AdminProvider
            config={{
              apiRoot:
                getEnv('REACT_APP_API_ROOT') ||
                'https://admin.hux-staging.sandbox.ddapps.xyz',
              debug: getEnv('REACT_APP_DEBUG') || false,
              startPage: getEnv('REACT_APP_START_PAGE') || '/',
              sidebar: {
                fixed: sidebarFixedOptions,
                roleBasedOptions: sidebarRolebaseOptions,
              },
              authMethod: {
                type: getEnv('REACT_APP_AUTH_METHOD') || 'password',
              },
            }}
          >
            {/* The BaseAdmin component loads all of the out of the box components exported by blueprint-ux-admin.
            You can extend the routes by providing a fragment with a list of Routes. */}
            <BaseAdmin>
              <>
                <Route path='/' Component={Dashboard} />

                {/* Categories */}
                <Route path='/categories' Component={CategoryList} />
                <Route path='/categories/new' Component={CategoryNew} />
                <Route path='/categories/:id/new' Component={CategoryNewTag} />
                <Route path='/categories/:id' Component={CategoryDetail} />
                <Route
                  path='/categories/:id/delete'
                  Component={CategoryTagDelete}
                />

                {/* Tags */}
                <Route path='/categories/:id/:slug' Component={TagDetail} />
                <Route
                  path='/categories/:id/:slug/delete'
                  Component={TagDelete}
                />

                <Route path='/decks/:id' Component={DeckDetail} />
                <Route path='/decks' Component={DeckList} />
              </>
            </BaseAdmin>
            <ToastContainer />
          </AdminProvider>
        )}
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
