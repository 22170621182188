import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TableCell,
  TableCellProps,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Link as UiLink } from '@mui/material';
import {
  AdminModal,
  AdminPage,
  AdminPaginatedList,
  AdminPaginatedListV2,
  AdminTableRow,
  AdminCard,
  toastSuccess,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const CategoryList = () => {
  const itemTemplate = ({ id, name, tagType, slug, tags }, index) => (
    <AdminTableRow key={index}>
      <TableCell>
        <UiLink component={Link} to={`/categories/${slug}`}>
          {name}
        </UiLink>
      </TableCell>
      <TableCell>
        {tags.map((tag: any) => (
          <>
            <UiLink component={Link} to={`/categories/${slug}/${tag.slug}`}>
              {tag.name}
            </UiLink>
            <br />
          </>
        ))}
      </TableCell>
    </AdminTableRow>
  );

  return (
    <>
      <AdminPage
        animation={true}
        title='Deck Categories'
        newAction='/categories/new'
      >
        <AdminPaginatedList
          columns={['category', 'tags', 'actions']}
          url='/admin/decks/meta/categories/type/deck'
          itemTemplate={itemTemplate}
          filterKeys={[]}
        />
        <AdminPaginatedList
          columns={['category', 'tags', 'actions']}
          url='/admin/decks/meta/categories/type/slide'
          itemTemplate={itemTemplate}
          filterKeys={[]}
        />
      </AdminPage>
    </>
  );
};

export default CategoryList;
