import {
  AdminPage,
  AdminCard,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { Grid } from '@mui/material';

const Dashboard = () => {
  return (
    <AdminPage title='Dashboard'>
      <Grid container>
        <Grid item xs={12}>
          <AdminCard>
            <iframe
              src='http://metabase.sandbox.ddapps.xyz/public/dashboard/79fcacda-0202-4db7-8178-8a474c52a610'
              width='100%'
              height='600'
            />
          </AdminCard>
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default Dashboard;
