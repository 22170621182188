import { Chip, Typography, Grid, Box, Link } from '@mui/material';

interface Tag {
  label: string;
  slug: string;
}

interface ITagBar {
  color: string;
  tags: Array<Tag>;
  onDelete?: (tagSlug: string) => void;
  inline?: boolean;
  style?: Object;
}

function TagBar({ color, tags, onDelete, inline, style }: ITagBar) {
  const tagValueChips = tags.map(function (tagItem: Tag, i: number) {
    return onDelete ? (
      <Chip
        key={i}
        label={tagItem.label}
        style={{
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: color,
        }}
        clickable
        onDelete={() => {
          onDelete(tagItem.slug);
        }}
      />
    ) : (
      <Link key={i} href={`/search?filters=${tagItem.slug}`}>
        <Chip
          label={tagItem.label}
          clickable
          style={{ marginRight: '8px', backgroundColor: color }}
        />
      </Link>
    );
  });

  return (
    <>
      <Grid
        container
        style={style}
        direction={inline ? 'row' : 'column'}
        rowGap={inline ? 0.5 : undefined}
        alignItems={inline ? 'center' : undefined}
        mr={2}
        paddingTop={2}
      >
        <Grid item>{inline ? tagValueChips : <Box>{tagValueChips}</Box>}</Grid>
      </Grid>
    </>
  );
}

export default TagBar;
