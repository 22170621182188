import {
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  AdminPage,
  AdminCard,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { useState, useEffect } from 'react';

const CategoryNewTag = () => {
  const { slug, id } = useParams<{ slug: string; id: string }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const [tag, setTag] = useState(undefined);
  const navigate = useNavigate();

  const deckAPI = new AdminScopedAPI(config, 'DeckAPI');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    deckAPI
      .get({ path: `/admin/decks/meta/tags/${slug}` })
      .then((data) => {
        setTag(data.data);
      })
      .catch((err) => console.error(err));
  };

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Please enter a name';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    const response = await deckAPI.post({
      path: `/admin/decks/meta/tags`,
      body: values,
    });

    if (response.success) {
      navigate(`/categories/${id}`, {
        state: { detail: 'newTrigger' },
      });
      return true;
    } else {
      console.error(response.message ?? 'error creating trigger');
      return false;
    }
  };

  const TagForm = ({ tag }: any) => {
    const formik = useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      validate: validate,
      initialValues: {
        name: '',
        category: id,
      },
      onSubmit: async (values) => {
        await onSubmit(values);
      },
    });

    return (
      <Grid xs={12} container>
        <AdminCard title='Details'>
          <form onSubmit={formik.handleSubmit}>
            <FormControl margin='dense' fullWidth style={{ paddingBottom: 20 }}>
              <Typography variant='subtitle1'>Name</Typography>
              <TextField
                error={formik.errors.name !== undefined}
                helperText={formik.errors.name}
                defaultValue={formik.values.name}
                value={formik.values.name}
                id='name'
                name='name'
                type='text'
                variant='outlined'
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl>
              <Button
                variant='contained'
                size='large'
                id='submit'
                type='submit'
              >
                Save Changes
              </Button>
            </FormControl>
          </form>
        </AdminCard>
      </Grid>
    );
  };

  return (
    <>
      <AdminPage title='Tag Detail'>
        <TagForm tag={tag} />
      </AdminPage>
    </>
  );
};

export default CategoryNewTag;
